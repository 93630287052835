//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -1.75rem;
	max-width: calc(100% + 1.75rem + 1.75rem);
	flex-wrap: wrap;

	.arrow-link,
	.btn{
		margin: 0 1.75rem 1.75rem;

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem!important;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: .1em;
	color: $gold!important;
	text-decoration: none!important;
	font-family: $main-font;
	text-decoration: none;
	text-align: center;
	padding: 1.7rem 1.7rem;
	background-color: transparent;
	border: .1rem solid $gold;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	position: relative;
	transition: $time;
	text-transform: uppercase;
	min-width: 22.1rem;

	@media (max-width: $md){
		min-width: 19rem;
	}
	
	*{
		transition: $time;
		color: $gold!important;
	}

	&:not(.no-link) {
		&:hover,
		&:focus-visible{
			outline: none;
			background-color: $gold;
			border-color: $gold;
			color: $white!important;

			*{
				color: $white!important;
			}
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{	
	&.white{
		color: $white!important;
		background-color: transparent;
		border-color: $white;

		&:hover,
		&:focus-visible{
			color: $green!important;
			background-color: $white;
			border-color: $white;
		}
	}

	&.green{
		color: $green!important;
		background-color: transparent;
		border-color: $green;

		&:hover,
		&:focus-visible{
			color: $white!important;
			background-color: $green;
			border-color: $green;
		}
	}

	&.big{
		font-size: 2.4rem!important;
		padding: 1.45rem 2.4rem;
		
		@media (max-width: $md){
			padding: 1.45rem 1.45rem;
		}
	}

	&.small{
		padding: 1.45rem 1.6rem;
		font-size: 1.6rem!important;
		min-width: 22.1rem;
	}
	
	&.no-link {
		pointer-events: none;
	}
}

