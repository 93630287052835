//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import '../../assets/js/evo-calendar/css/evo-calendar.css';
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
@import 'quote-builder';
@import 'calendar'; 
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0;
	width: 100%;

	section{
		overflow: hidden;
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.inner{
	position: relative;
}

img.full-bg,
.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

img.full-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

// .breadcrumb-con{
// 	@include font-size(18);
// 	line-height: 1.2em;
// 	margin-bottom: 50px;
// 	color: $colour;
// 	font-weight: 400;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: $md){
// 		margin-bottom: 30px;
// 	}

// 	span{
// 		color: $white;
// 		@include font-size(18);
// 		line-height: 1.2em;
// 		display: inline-block;

// 		@media (max-width: $md){
// 			@include font-size(12);
// 		}
// 	}

// 	a{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		color: $red;
// 		font-weight: 400;
// 		margin-right: .2em;
// 		position: relative;
// 		display: inline-block;

// 		@media (max-width: $md){
// 			@include font-size(12);
// 		}

// 		&:after{
// 			content: '/';
// 			display: inline-block;
// 			margin-left: .4em;
// 			color: $colour;
// 		}


// 		&:hover,
// 		&:focus-visible{
// 			color: $red;
// 			text-decoration: none;
// 		}
// 	}

// 	> a{
// 		margin-left: 0;
// 	}

// 	.breadcrumb_last{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		color: $colour;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		font-weight: 400;
// 		position: relative;

// 		@media (max-width: $md){
// 			@include font-size(12);
// 		}
// 	}
// }

//
//!! BREADCRUM END
//

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3.1rem;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 0rem;
		@include font-size(20);
		position: relative;
		z-index: 1;

		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					background-color: rgba($green, .05);
				}
			}

			span,
			a{
				@include font-size(24);
				display: block;
				color: $green;
				font-weight: 500;
				padding: 0;
				text-decoration: none;
				width: 4.4rem;
				height: 4.4rem;
				border-radius: 4.4rem;
				line-height: 4.4rem;
				text-align: center;
				font-family: $alt-font;

				&:hover,
				&:focus-visible{
					color: $gold;
				}
			}


			&.ellipse{
				color: $blue;
				font-weight: 500;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				background-position: 50% 20%;
				background-repeat: no-repeat;
				height: 2.041rem;
				width: 4.559rem;
				background-size: 100% auto;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{
			margin-left: 2.6rem;
			
			a{
				background-image: url(../images/arrow-right-gold.svg);

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-right.svg);
				}
			}
			
		}

		&.prev{
			margin-right: 2.6rem;

			a{
				background-image: url(../images/arrow-left-gold.svg);

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-left.svg);
				}
			}
		
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		36;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	a{
		@include font-size($social-size);
		line-height: $social-size/10+rem;
		height: $social-size/10+rem;
		min-width: $social-size/10+rem;	
		display: block;
		text-align: center;
		color: $green;
		text-decoration: none;
		padding: 0;
		font-weight: 100;
		margin: 0 2.95rem 2.95rem 0;

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			
			i{
				color: $gold!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size/10+rem;
			height: $social-size/10+rem;
			min-width: $social-size/10+rem;	
			display: flex;
			align-items: center;
			justify-content: center;

			&:before{
				transition: $time/10;
			}
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

	
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .4s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.arrow-link{
	display: flex;
	align-items: center;
	text-decoration: none;
	color: $gold!important;
	text-transform: uppercase;
	@include font-size(18);
	font-weight: 500;
	letter-spacing: .1em;

	svg{
		width: 2.5313rem;
		margin-left: .6em;
		transform: translate(0rem);

		path{
			fill: $gold;
		}
	}

	&:hover,
	&:focus-visible{
		text-decoration: none;
		color: $green!important;
		
		svg{
			transform: translate(1rem);

			path{
				fill: $green;
			}
		}
	}

	&.white{
		color: $white!important;

		svg{

			path{
				fill: $white;
			}
		}

		&:hover,
		&:focus-visible{
			color: $gold!important;

			svg{

				path{
					fill: $gold;
				}
			}
		}
	}

	&.back {
		svg{
			margin-left: 0;
			margin-right: .6em;
		}

		&:hover,
		&:focus-visible{
			
			svg{
				transform: translate(-1rem);
			}
		}
	}
}

.hero-section:not(.home){

	@media (max-width: $md){

		.col-12.col-12.col-12{
			display: flex;
			//flex-direction: column;
			justify-content: flex-start;

			img{
				margin-bottom: 0;
			}

			.text{
				margin: auto auto;
			}
		}
	}
}

.hero-section{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($blue-dark, .35);
		z-index: 2;
	}

	&.bg-gold{
		background-color: $gold;
		background-image: url(../images/hero-feathers.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		@media (max-width: $md){
			background-image: url(../images/hero-feather-mob.svg);
			background-size: 70.34096rem auto;
			background-position: 62% -12.4rem;
		}

		&:after{
			display: none;
		}

		.col-12{

			// .logo-link{
			// 	margin: 0 auto 1rem;
	
			// 	@media (max-width: $md){
			// 		margin: 0 auto 1rem;
			// 	}
			// }
	

			h2{
				margin-bottom: .13em;

				@media (max-width: $md){
					margin-bottom: .53em;
				}
			}

			.text{
				margin: 0 auto;
				width: 51rem;
			}
		}
	}

	img.full-bg,
	video{
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
	}
	
	.container{
		position: relative;
		z-index: 3;
	}

	.row{
		height: 74.4rem;
		//min-height: 100vh;
		text-align: center;
		padding-top: 3.8rem;
		padding-bottom: 10.5rem;

		@media (max-width: $md){
			padding-bottom: 21rem;
			padding-bottom: 13rem;
			padding-top: 3rem;
		}

		*{
			color: white;
		}

		.blog &{

			@media (max-width: $md){
				min-height: 59.4rem;
			}
		}

		.single-post &{

			@media (max-width: $md){
				min-height: 100vh;
			}
		}
	}

	&.tall{

		.row{
			min-height: 90rem;
			padding-top: 18.2rem;

			@media (max-width: $md){
				min-height: 93.2rem;
				//min-height: 100vh;
				padding-top: 15.1rem;
				padding-bottom: 14rem;
			}

			.col-12{
				//flex-direction: column;
				display: flex;
				justify-content: center;

				@media (max-width: $md){
					position: relative;
					padding-bottom: 18rem;

					.btn-con{
						position: absolute;
						bottom: .7rem;
						width: calc(100% + 1.75rem + -2.25rem);
						max-width: calc(100% + 1.75rem + -2.25rem);

						.btn{
							width: 100%;
						}
					}
				}

				img{
					width: 47.6rem;
					margin: 0 auto 6.6rem;

					@media (max-width: $md){
						width: 28.88rem;
						margin: 0 auto 3.2rem;
					}
				}
			}
		}
		
	}

	.next-link{
		position: absolute;
		bottom: 4.9rem;
		left: 50%;
		transform: translateX(-50%);
		color: $white;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: .1em;
		@include font-size(18);
		text-align: center;

		@media (max-width: $md){
			width: 100%;
			bottom: 14rem;
			bottom: 7rem;
		}
		
		&:hover,
		&:focus-visible{
			
			svg{
				transform: translateY(.5rem);
			}
		}

		svg{
			margin: 1.3rem auto 0;
			transform: translateY(0);
		}
	}

	.col-12{
		//flex-direction: column;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		img{
			width: 100%;
		}

		.logo-link{
			align-self: flex-start;
			margin: 0 auto 1rem;
			width: 29rem;

			@media (max-width: $md){
				width: 23.95rem;
				margin: 0 auto 1rem;
			}
		}

		.content-con{
			width: 100%;
			align-self: flex-start;
		}

		br{

			@media (max-width: $md){
				display: none;
			}

			.page-id-195 &{

				@media (min-width: $md + 1){
					display: none;
				}

				@media (max-width: $md){
					display: block;
				}
			}
		}

		.btn-con{
			margin-top: 4rem;
		}

		.btn{
			min-width: 26.9rem;

			&:nth-of-type(1){

				&:hover,
				&:focus-visible{
					outline: none;
					background-color: $gold;
					border-color: $gold;
					color: $white!important;

					*{
						color: $white!important;
					}
				}
			}

			&:nth-of-type(2){

				&:hover,
				&:focus-visible{
					outline: none;
					background-color: $green;
					border-color: $green;
					color: $white!important;

					*{
						color: $white!important;
					}
				}
			}
		}
	}
}

.table-section{
	padding-top: 2rem;
	padding-bottom: 2.5rem;

	@media (max-width: $md){
		padding-top: .8rem;
		padding-bottom: 8.4rem;
	}

	.container{
		max-width: 102.3rem;
	}

	@media (max-width: $md){
		
		table{

			thead{
				display: none;
			}

			tbody{

				tr{
					display: block;

					td{
						display: block;
						border: none;
						width: 15.5rem;
						margin-left: calc(100% - 15.5rem);
						border-left: .1rem solid $gold;
						position: relative;

						&:before{
							content: attr(data-title);
							position: absolute;
							top: -.1rem;
							right: 100%;
							width: calc(100vw - 4rem - 15.5rem );
							max-width: calc(100vw - 4rem - 15.5rem );
							padding: 1.3rem 2.3rem;
							background-color: inherit;
							min-height: 100%;
							display: flex;
							align-items: center;
						}

						+ td{
							border-top: .1rem solid $gold;

							&:before{
								border-top: .1rem solid $gold;
							}
						}
					}
				}
			}

		}
	}
}

.text-with-image-section + .text-with-feather-section{
	
	.row{
		padding-top: 0;
	}
}

.text-with-feather-section{

	.page-id-206 &{

		h3{

			em{
				line-height: 1.30645;
			}
		}
	}

	&:has(+ .table-section){
		
		.row{
			padding-bottom: 3.7rem;
		}
	}


	@media (max-width: $md){
		
		&:has(+ .table-section),
		&:has(+ .all-partners-section){
		
			.row{
				padding-bottom: .6rem;
			}
		}
	}

	&.hide-feather{

		.row{
			padding-top: 11.3rem;
			padding-bottom: 8.9rem;

			@media (max-width: $md){
				padding-top: 8.6rem;
				padding-bottom: 4rem;
			}
		}
		
	}

	&.center-text{

		.row{
			justify-content: center;
			text-align: center;
			padding-top: 10.8rem;

			@media (max-width: $md){
				padding-top: 5.8rem;
			}

			.btn-con{
				justify-content: center;
			}


			.text-side{

				@media (min-width: $md + 1){
					flex: 0 0 55%;
					max-width: 55%;
				}
			}

			.feather-side{
				display: none;
			}
		}
	}

	.row{
		padding-top: 15rem;
		padding-bottom: 13.7rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 8.6rem;
			padding-bottom: 7.7rem;
			
			br{
				display: none;
			}
		}
	}

	.text-side{
		padding-bottom: 1rem;
	}

	.feather-side{

		img{
			float: right;
			opacity: .09;

			@media (min-width: $md + 1){
				margin-right: .1em;
			}

			@media (max-width: $md){
				display: none;
			}

			&:has(+ .over-text){
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}

			+ .over-text{

			}
		}

		.over-text{
			width: 100%;
			clear: both;
		}
	}

	.btn-con{
		margin-top: 3.2rem;
	}

	.arrow-link{
		color: $green!important;

		svg{

			path{
				fill: $green;
			}
		}

		&:hover,
		&:focus-visible{
			color: $gold!important;

			svg{

				path{
					fill: $gold;
				}
			}
		}
	}

	h1 + hr{
		margin-top: -.9rem;
	}
}

.bg-blue{

	.text{
		
		*:not(a) { 
			color: $white;
		}

		a {
			text-decoration: underline;
			text-underline-position: under;
			text-decoration-color: transparent;
			text-decoration-thickness: from-font;

			&:hover{
				color: $cream;
				text-decoration-color: $gold;
			}
		}

		em{
			color: $gold;
		}
	}
}

.bg-gold{

	.text{
		
		*:not(a){
			color: $white;
		}

		a {
			color: $green;
			text-decoration: underline;
			text-underline-position: under;
			text-decoration-color: transparent;
			text-decoration-thickness: from-font;

			&:hover{
				text-decoration-color: $green;
			}
		}

		em{
			color: $green;
		}

		.btn{
			color: $green!important;
			border-color: $green!important;
	
			&:hover,
			&:focus-visible{
				background-color: $green;
				color: $white!important;
			}
		}
	}
}

.events-section,
.block-text-with-image-links-section{
	padding-top: 10.5rem;
	padding-bottom: 6.4rem;
	position: relative;

	@media (max-width: $md){
		padding-top: 7.3rem;
		padding-bottom: 2.4rem;
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 78.7rem;

		@media (max-width: $md){
			min-height: 78.7rem;
			height: calc(100% - 20.3rem);
		}
	}

	&.bg-blue{
		
		&:before{
			background-color: $blue;
		}
	}

	&.bg-cream{
		
		&:before{
			background-color: $cream;
		}
	}

	&.bg-gold{
		
		&:before{
			background-color: $gold;
		}
	}

	.container{
		max-width: 102.3rem;
		position: relative;
		z-index: 1;
	}

	.text-top{
		text-align: center;
		margin-bottom: 7.55rem;

		.text{
			margin: 0 auto;
			width: 47rem;
		}
	}
}

.contact-section + .events-section{
	padding-top: 4.9rem;
}

.events-section{
	padding-top: 16.2rem;

	@media (max-width: $md){
		padding-top: 7.5rem;
	}

	&:before{
		min-height: 78.7rem;
		height: calc(100% - 38.5rem);
	}

	.page-id-290 &{
		padding-top: 10.5rem;

		@media (max-width: $md){
			padding-top: 7.4rem;
		}

		.text-top{
			margin-bottom: 5.4rem;

			@media (max-width: $md){
				margin-bottom: 3.2rem;
			}

			.btn-con{
				display: none;
			}
		}
	}

	.btn-con{
		margin-top: 4rem;

		@media (max-width: $md){
			margin-top: 2.4rem;
		}
	}

	.text-top{
		margin-bottom: 7.6rem;

		@media (max-width: $md){
			margin-bottom: 6.3rem;
		}
	}
}

.e-item,
.i-item {
	margin-bottom: 3.5rem;

	.inner{
		position: relative;
		aspect-ratio: .8281/1;
		width: 100%;
		display: flex;
		align-items: flex-end;

		&:hover,
		&:focus-visible{

			&::before{
				background-color: rgba($gold, .8);
			}
		}

		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($blue-dark, .5);
			transition: $time;
			z-index: 1;
		}

		*{
			color: $white;
		}

		.bottom{
			padding: 0 4rem 6.3rem;
			width: 100%;
			position: relative;
			z-index: 2;
			pointer-events: none;

			*:last-child{
				margin-bottom: 0;
			}
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		a.a-over{
			z-index: 4;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ .bottom{

					.btn{
						color: $green!important;
						background-color: $white;
						border-color: $white;
					}
				}
			}
		}
	}
}

.e-item{

	.inner{
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		height: 100%;

		.top{
			padding: 8.3rem 5rem 6.3rem;
			width: 38rem;
			position: relative;
			z-index: 2;

			@media (max-width: $md){
				padding: 6.3rem 4rem 2.4rem;
			}
		}

		.bottom {
			padding: 0 4.9rem 4.5rem;
			pointer-events: auto;

			@media (max-width: $md){
				padding: 0 4rem 3.4rem;
			}
		}

		p{
			@include line-height(20,26);
		}

		.date{
			@include font-size(32);
			@include line-height(32,36);
			font-family: $alt-font;
			margin-bottom: .11em;
		}

		.small-title,
		.time{
			margin-bottom: .45em;
			font-weight: 500;
		}

		.small-title{
			text-transform: uppercase;
			margin-bottom: .41em;
			@include font-size(18);
			letter-spacing: .1em;
		}

		.btn-con{
			margin-top: 3.8rem;

			@media (max-width: $md){
				margin-top: 2.7rem;
			}
		}

		.btn{
			position: static;
			width: 100%;
		}
	}
}

.e-item {
	&.event-wrapper {
		display: flex;

		.inner {
			height: auto;
			justify-content: flex-start;
		}
	}
}

.text-with-feather-section + .alternating-image-and-text-section{
	padding-top: 11.8rem;
	padding-bottom: 8.5rem;

	@media (max-width: $md){
		padding-top: 7.8rem;
		padding-bottom: .4rem;
	}

	.feather-bottom{
		display: none;
	}
}

.carousel-blocks-section + .alternating-image-and-text-section{
	padding-top: 15.8rem;

	@media (max-width: $md){
		padding-top: 4.65em;
	}
}

.from-the-blog-section,
.blog-archive-section,
.alternating-image-and-text-section{
	padding-top: 6.2rem;
	padding-bottom: 6.4rem;
	position: relative;

	@media (max-width: $md){
		padding-top: 2.6rem;
	}

	&:has(+ .text-and-image-over-back-colour-section.text-and-image-over-back-colour-section){
		padding-bottom: 20.8rem;

		@media (max-width: $md){
			padding-bottom: 5.4rem;
		}
	}

	&.bg-blue{
		background-color: $blue;
	}

	&.bg-cream{
		background-color: $cream;
	}

	&.bg-gold{
		background-color: $gold;
	}

	&.text-left{

		.text-top{
			text-align: left;
			margin-bottom: 8.75rem;

			@media (max-width: $md){
				margin-bottom: 3.75rem;
			}
	
			.text{
				margin: 0;
				width: 62rem;
			}
		}
	}

	&.text-right{

		.text-top{
			text-align: right;
			margin-bottom: 8.75rem;
			display: flex;
			justify-content: flex-end;

			@media (max-width: $md){
				margin-bottom: 3.75rem;
			}
	
			.text{
				margin: 0;
				width: 62rem;
			}
		}
	}

	&.hide-feathers{
		padding-bottom: 14.1rem;

		@media (max-width: $md){
			padding-bottom: 3.3rem;
		}

		.feather-top{
			opacity: 0;
			visibility: hidden;
		}

		.feather-bottom{
			display: none;
		}
	}

	.container{
		max-width: 102.3rem;
		position: relative;
		z-index: 1;

		+ .container{
			max-width: 140.8rem;

			@media (min-width: $lg + 1){
				padding-right: 8.15rem;
				padding-left: 8.15rem;
				
				.col-12{
					padding-right: 8.15rem;
					padding-left: 8.15rem;
				}

				.row{
					margin-right: -8.15rem;
					margin-left: -8.15rem;
				}
			}

			.row{
				align-items: flex-start;

				.a-item{

					&:nth-of-type(2n + 2){

						.img-con{

							h3{
								left: auto;
								right: -11.5rem;

								@media (max-width: 1577px){
									right: -1.1rem;
								}
							}
						}
					}
	
					&:nth-of-type(2n + 4){

						@media (min-width: $md + 1){
							margin-top: 39.8rem;
						}
						
					}
				}
			}
		}
	}

	.text-top{
		text-align: center;
		margin-bottom: 11.45rem;

		@media (max-width: $md){
			margin-bottom: 3.95rem;
		}

		.text{
			margin: 0 auto;
			width: 47rem;
			width: 57rem;
		}
	}
}

.from-the-blog-section,
.blog-archive-section{
	padding-top: 10.6rem;
	padding-bottom: 13.7rem;

	@media (max-width: $md){
		padding-top: 5.6rem;
		padding-bottom: 6.6rem;
	}

	&.related{
		padding-top: 17.8rem;
		padding-bottom: 9.2rem;

		@media (max-width: $md){
			padding-top: 8.1rem;
			padding-bottom: 3.7rem;
		}

		.title-con{
			text-align: center;
			margin-bottom: 9.7rem;

			@media (max-width: $md){
				margin-bottom: 1.7rem;
			}
		}
	}

	.container{

		+ .container{
			max-width: 128rem;

			@media (min-width: $lg + 1){
				padding-right: 14.55rem;
				padding-left: 14.55rem;
				
				.col-12{
					padding-right: 14.55rem;
					padding-left: 14.55rem;
				}

				.row{
					margin-right: -14.55rem;
					margin-left: -14.55rem;
				}
			}
		}
	}

	.filter{
		margin-bottom: 9.9rem;
		text-align: center;

		@media (max-width: $md){
			margin-bottom: 7.9rem;
		}

		.filter-slide{
			width: auto;
			margin: 0 auto;

			.swiper-slide{
				margin: 0 .55rem 1.1rem;
				width: auto;
				pointer-events: none;

				a{
					pointer-events: auto;
				}
			}

			.swiper-wrapper-con{
				margin: 0 auto;
				display: inline-flex;
				overflow: visible!important;
			}
		}

		a{
			@include font-size(18);
			font-weight: 500;
			letter-spacing: .1em;
			opacity: .25;
			border: .1rem solid $green;
			padding: 1.55rem 2.1rem;
			border-radius: 3rem;
			text-transform: uppercase;
			margin: 0 .55rem 1.1rem;
			margin: 0;
			text-decoration: none;
			min-width: 9.3rem;
			text-align: center;
			display: inline-block;

			@media (max-width: $md){
				white-space: nowrap;
			}

			&:hover,
			&:focus-visible{
				opacity: 1;
			}

			&.active{
				opacity: 1;
				background-color: $green;
				color: $white;
				pointer-events: none;
			}
		}
	}

	.a-item.a-item.a-item.portrait{

		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;

			.feather-top{
				margin-top: 1.5rem;
				margin-bottom: -20rem;
				max-width: 47.488rem;
				pointer-events: none;

				&.mid{
					margin-top: -27.6rem;
					margin-bottom: 0;
					position: absolute;
					z-index: -1;
					pointer-events: none;
				}
			}

			.feather-bottom{
				margin-top: -46.3rem;
				margin-left: -76.8rem;
				margin-bottom: 7.8rem;
				max-width: 47.488rem;
				pointer-events: none;

				@media (max-width: 1080px){
					margin-left: -227%;
				}

				@media (max-width: 991px){
					margin-left: -137%;
				}

				&.mid{
					margin-top: -36.7rem;
					margin-left: -12.6rem;
					position: absolute;
					z-index: -1;
					pointer-events: none;
				}
			}

			&:nth-of-type(2n + 3){
				margin-top: -18.8rem;
			}

			&:nth-of-type(2n + 4){
				margin-top: 10.4rem;
			}
		}

		.img-con{
			margin-bottom: 3.1rem;

			@media (max-width: $md){
				margin-bottom: 2.3rem;
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				&:hover,
				&:focus-visible{
					background-color: rgba($blue, .6);
				}
			}

			h3{
				pointer-events: none;
			}
		}

		.under{
			text-align: center;
		}

		h4{
			margin-bottom: .4em;

			@media (max-width: $md){
				margin-bottom: 1rem;
			}
		}

		p{
			margin-bottom: 1.15em;
		}

		.arrow-link{
			display: inline-flex;
		}

		.cats{
			margin-bottom: 1.3rem;

			@media (max-width: $md){
				margin-bottom: 2rem;
			}

			a{
				text-decoration: none;
				text-transform: uppercase;
				opacity: .25;
				font-weight: 500;
				letter-spacing: .1em;

				&:hover,
				&:focus-visible{
					opacity: .75;
				}

				&:has(+ a){

					&:after{
						content: ', ';
					}
				}
			}
		}
	}
}

.events-section ~ .text-with-image-section{
	padding-top: 8rem;

	@media (max-width: $md){
		padding-top: 1.7rem;
	}

	+ .text-with-image-section{
		padding-top: 10.7rem;

		@media (max-width: $md){
			padding-top: 4.7rem;
		}
	}
}

.carousel-blocks-section + .text-with-image-section,
.text-with-feather-section + .text-with-image-section{
	padding-top: 1.7rem;

	~ .text-with-image-section{
		padding-top: 10.7rem;

		@media (max-width: $md){
			padding-top: 2.7rem;
		}
	}
}

.carousel-blocks-section + .text-with-image-section{
	padding-bottom: 5.2rem;

	@media (max-width: $md){
		padding-bottom: 2.6rem;
	}
}

.text-with-image-section{
	padding-top: 18.7rem;
	padding-bottom: 18.7rem;

	@media (max-width: $md){
		padding-top: 8.7rem;
		padding-bottom: 11.1rem;

		.image-side{
			order: 1!important;
			margin-bottom: 3.1rem;
		}

		.text-side{
			order: 2!important;
		}
	}

	&.bg-blue{
		background-color: $blue;
	}

	&.bg-cream{
		background-color: $cream;
	}

	&.bg-gold{
		background-color: $gold;
	}

	&:has(+ .text-with-image-section){
		padding-bottom: 4.2rem;
	}

	&.landscape{

		&.text-left{

			.text-side{

				@media (min-width: $md + 1){
					margin-left: 0%;
					margin-right: 10%;
				}
			}
		}

		.image-side{

			@media (min-width: $md + 1){
				flex: 0 0 50%;
				max-width: 50%;
			}

			.img-con{
				aspect-ratio: 1.4268/1;
			}
		}

		.text-side{

			@media (min-width: $lg + 1){
				flex: 0 0 40%;
				max-width: 40%;
				margin-left: 10%;
			}
		}
	}

	&.portrait{

		&.text-right{

			.text-side{

				@media (min-width: $md + 1){
					margin-left: 20%;
					margin-right: 0%;
				}
			}
		}

		.image-side{

			@media (min-width: $md + 1){
				flex: 0 0 40%;
				max-width: 40%;
			}

			.img-con{
				aspect-ratio: .7963/1
			}
		}

		.text-side{

			@media (min-width: $lg + 1){
				flex: 0 0 40%;
				max-width: 40%;
				margin-right: 20%;
			}

			@media (max-width: $lg){
				
				@media (min-width: $md + 1){
					margin-right: 10%;
				}
			}
		}
	}


	&.text-right{

		@media (min-width: $md + 1){
			
			.text-side{
				order: 2;
			}
		}
	}

	.row{
		align-items: center;
	}

	.arrow-link{
		color: $green!important;

		svg{

			path{
				fill: $green;
			}
		}

		&:hover,
		&:focus-visible{
			color: $gold!important;

			svg{

				path{
					fill: $gold;
				}
			}
		}
	}

	.text-side{

		h3{
			margin-bottom: .45em;
		}

		.btn-con{
			margin-top: 3.6rem;

			@media (max-width: $md){
				margin-top: 2.8rem;
			}
		}
	}
}

.a-item{
	margin-bottom: 6.8rem;

	@media (max-width: $md){
		margin-bottom: 5.5rem;
	}

	&.portrait{

		@media (min-width: $md + 1){
			flex: 0 0 45.4%;
			max-width: 45.4%;
		}

		@media (max-width: $md){
			padding-left: 7rem;
			padding-right: 7rem;
		}

		.img-con{
			aspect-ratio: .8003/1
		}
	}

	&.landscape{
		
		@media (min-width: $md + 1){
			flex: 0 0 54.6%;
			max-width: 54.6%;
		}
		
		@media (max-width: $md){
			padding-left: 4rem;
			padding-right: 4rem;

			.under{
				padding-left: 3rem;
				padding-right: 3rem;
			}
		}

		.img-con{
			aspect-ratio: 1.4302/1;
		}
	}

	.feather-top,
	.feather-bottom{
		opacity: .09;
	}

	.feather-top{
		transform: rotate(-90deg);
		margin-top: 5.1rem;
		margin-left: 0.2rem;
		margin-bottom: -13rem;
		width: 47.488rem;

		@media (max-width: $md){
			display: none;
		}
	}

	.feather-bottom{
		transform: rotate(90deg);
		margin-top: -22.6rem;
		margin-left: -63.8rem;
		margin-bottom: -5.6rem;
		width: 47.488rem;

		@media (max-width: 1080px){
			margin-left: -127%;
		}

		@media (max-width: 991px){
			margin-left: -105%;
		}

		@media (max-width: $md){
			display: none;
		}
	}

	.inner{
		width: 100%;
	}

	.img-con{
		position: relative;
		margin-bottom: 4rem;

		@media (max-width: $md){
			margin-bottom: 2.8rem;
		}

		&::before{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60%;
			opacity: .4;
			background: linear-gradient(to bottom, rgba(3,7,8,0) 0%, rgba(3,7,8,0.81) 33%, rgba(3,7,8,1) 100%);
		}


		h3{
			position: absolute;
			left: -12.9rem;
			bottom: 6.9rem;
			margin-bottom: 0;
			@include line-height(62,60);
			z-index: 2;
			pointer-events: none;

			@media (max-width: $xl){
				@include font-size(31.5);
			}

			@media (max-width: 1577px){
				left: -5.1rem;
			}

			@media (max-width: $md){
				bottom: 4.8rem;
			}
		}
	}

	.btn-con{
		margin-top: 3.2rem;

		.btn{
			min-width: 34.9rem;

			@media (max-width: $lg){
				min-width: 0;
				width: 100%;
			}
		}
	}
}

section{

	+.team-section,
	+ .text-and-image-over-back-colour-section{

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 36rem;
		}
	}

	&.bg-cream{

		+.team-section,
		+ .text-and-image-over-back-colour-section{

			&:before{
				background-color: $cream;
			}
		}
	}

	&.bg-blue{

		+.team-section,
		+ .text-and-image-over-back-colour-section{

			&:before{
				background-color: $blue;
			}
		}
	}

	&.bg-gold{

		+.team-section,
		+ .text-and-image-over-back-colour-section{

			&:before{
				background-color: $gold;
			}
		}
	}
}

.team-section,
.text-and-image-over-back-colour-section{
	position: relative;
	padding-top: 7.6rem;

	@media (max-width: $md){
		padding-top: 0;
	}

	.row{

		@media (max-width: 1080px){
			justify-content: center;
		}
	}

	&:after{
		content: '';
		position: absolute;
		left: 4.9rem;
		right: 4.9rem;
		top: 7.6rem;
		bottom: 0rem;

		@media (max-width: 1600px){
			left: 50%;
			right: 0;
			transform: translateX(-50%);
			max-width: calc(100vw - 2rem);
			width: 100%;
		}

		@media (max-width: $md){
			left: 50%;
			right: 0;
			transform: translateX(-50%);
			max-width: 102.3rem;
			width: 100%;
		}

		@media (max-width: $md){
			top: 0;
			bottom: auto;
			height: calc(100% - 29rem);
		}
	}

	&.bg-blue{

		&:after{
			background-color: $blue;
		}
	}

	&.bg-cream{

		&:after{
			background-color: $cream;
		}
	}

	&.bg-gold{

		&:after{
			background-color: $gold;
		}

		.btn{
			color: $green!important;
			border-color: $green!important;

			&:hover,
			&:focus-visible{
				background-color: $green;
				color: $white!important;
			}
		}
	}

	.container{
		max-width: 102.3rem;
		position: relative;
		z-index: 2;
		padding-top: 7.9rem;
		padding-bottom: 1.7rem;

		@media (max-width: 1600px){
			
			@media (min-width: $md + 1){
				width: calc(100vw - 6rem );
			}
		}

		@media (max-width: $md){
			padding-top: 7.4rem;
		}
	}

	.text-side{

		@media (max-width: 1080px){
			flex: 0 0 100%;
			max-width: 100%;
		}

		.text{
			width: 36rem;

			@media (max-width: 1080px){
				width: 100%;
				text-align: center;
				margin-bottom: 6.3rem;
			}
		}
	}

	h1{
		margin-bottom: .25em;

		@media (max-width: $md){
			margin-bottom: .45em;
		}
	}

	.btn-con{
		margin-top: 4.1rem;

		@media (max-width: 1080px){
			margin-top: 2.8rem;
			justify-content: center;

			.btn{
				min-width: 29rem;
			}
		}
	}

	.image-side{
		margin-top: -15.5rem;
		margin-bottom: 3.5rem;

		@media (max-width: 1080px){
			max-width: 100%;
			margin-top: 0;
		}

		+ .image-side{
			margin-top: 0;
		}
	}

	.img-con{
		aspect-ratio: .8281/1;
		width: 100%;
		display: flex;
		align-items: flex-end;
		position: relative;

		.bottom{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 3.8rem 3.3rem;

			*{
				color: $white;
			}
		}
	}
}

.team-section{

	&:after{

		@media (max-width: $md){
			height: 100%;
		}
	}

	.container{
		padding-bottom: 13.9rem;

		@media (max-width: $md){
			padding-top: 1rem;
			padding-bottom: 4.9rem;
		}
	}

	.text-side{
		padding-top: 6.4rem;

		.text{
			width: 34rem;
			
			@media (max-width: $md){
				margin-right: auto;
				margin-left: auto;
			}
		}

		h1{
			margin-bottom: .6em;
		}
	}

	.image-side{

		&:nth-of-type(3){

			.img-con{

				&:after{
					content: '';
					position: absolute;
					top: -12.6rem;
					left: -16.5rem;
					width: 47.488rem;
					height: 47.7rem;
					background-image: url(../images/team-feather-top.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					z-index: -1;
				}
			}
		}

		&:last-of-type:nth-of-type(even){

			.img-con{

				&:after{
					content: '';
					position: absolute;
					bottom: -4.6rem;
					right: -16.3rem;
					width: 47.488rem;
					height: 47.7rem;
					background-image: url(../images/team-feather-bottom.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					z-index: -1;
				}
			}
		}

		.bottom{

			h4{
				width: 18rem;
				margin-bottom: 1.3rem;
			}

			h5{
				text-transform: capitalize;
			}
		}
	}
}

.alternating-image-and-text-section + .carousel-blocks-section{
	padding-top: 17.1rem;

	@media (max-width: $md){
		padding-top: 8.8rem;
	}

	.text-top{
		margin-bottom: 5.95rem;

		@media (max-width: $md){
			margin-bottom: 1.65rem;
		}

		.text{
			width: 60rem;
		}
	}
}

.all-partners-section,
.carousel-blocks-section{
	padding-top: 14.7rem;
	padding-bottom: 12.7rem;

	@media (max-width: 1080px){
		padding-bottom: 12.7rem;
	}

	@media (max-width: $md){
		padding-top: 1.7rem;
	}

	&.bg-blue{
		background-color: $blue;

		.swiper-pagination.swiper-pagination-fraction{
			color: $cream;
		}

		.under{

			.number ~ *{
				color: $cream;

				em{
					color: $gold;
				}
			}
		}
	}

	&.bg-cream{
		background-color: $cream;
	}

	&.bg-gold{
		background-color: $gold;
	}

	&.remove-numbers{
		padding-bottom: 12rem;

		.number{
			display: none!important;
		}

		.swiper-slide{

			h4{
				margin-bottom: 1.35em;
			}
		}
	}

	.container{

		+ .container{
			max-width: 153.6rem;
		}
	}

	.text-top{
		margin-bottom: 4.65rem;

		@media (max-width: $md){
			margin-bottom: .65rem;
		}

		.text{
			width: 81rem;

			@media (min-width: $md + 1){
				max-width: calc(100% - 22rem);
			}
		}
	}

	.partner-list-slide,
	.count-slide{
		width: calc(100% + 1.75rem + 1.75rem);
		max-width: calc(100% + 1.75rem + 1.75rem);
		margin-left: -1.75rem;

		// @media (max-width: $b3){
		// 	width: calc(100% + .85rem + .85rem);
		// 	max-width: calc(100% + .85rem + .85rem);
		// 	margin-left: -.85rem
		// }

		

		@media (min-width: $md + 1){
			
			.swiper-button-prev,
			.swiper-button-next{
				top: -10.8rem;
				transform: none;
			}

			.swiper-button-prev{
				left: auto;
				right: 18.9rem;
			}

			.swiper-button-next{
				right: 11rem;
			}

			.swiper-pagination.swiper-pagination-fraction{
				display: none!important;
			}
		}

		@media (max-width: 1200px){

			.swiper-button-prev{
				right: 9.65rem;
			}

			.swiper-button-next{
				right: 1.75rem;
			}
		}

		@media (max-width: $md){
			
			.swiper-pagination.swiper-pagination-fraction{
				bottom: -4.6rem;
				@include font-size(24);
				line-height: 1;
				font-family: $alt-font;
			}
		
			.swiper-button-prev,
			.swiper-button-next{
				transform: none;
				top: auto;
				bottom: -4.6rem;
			}
		
			.swiper-button-prev{
				left: calc(50% - 17.2rem);
				right: auto;

				@media (max-width: $md){
					left: 2.1rem;
				}
			}
		
			.swiper-button-next{
				right: calc(50% - 17.2rem);

				@media (max-width: $md){
					right: 2.1rem;
				}
			}
		}
	}

	.count-slide{

		@media (min-width: 991px){

			.swiper-button-prev,
			.swiper-button-next{
				display: none!important;
			}
		
			&:has(.swiper-slide:not(.swiper-slide-duplicate):nth-of-type(9)){

				.swiper-button-prev,
				.swiper-button-next{
					display: block!important;
				}
			}
		}
	}

	.swiper-slide{
		padding: 0 1.75rem 1.75rem;

		@media (max-width: $md){
			padding: 0 1.75rem 0;
		}

		img{
			margin-bottom: 8.1rem;

			@media (max-width: $md){
				margin-bottom: 5.6rem;
				aspect-ratio: .8297/1;
			}
		}

		.img-con{
			aspect-ratio: .8297/1;
			margin-bottom: 8.1rem;

			@media (max-width: $md){
				margin-bottom: 5.6rem;
			}
		}

		.under{
			position: relative;
			margin-bottom: 1.3em;

			@media (max-width: $md){
				margin-bottom: .9em;
			}

			@media (max-width: 576px){
				min-height: 0!important;
			}

			.number{
				position: absolute;
				opacity: .25;
				@include font-size(200);
				@include line-height(200,273);
				font-family: $alt-font2;
				color: $gold;
				top: -.435em;
				z-index: -1;
			}

			h4{

				@media (max-width: $md){
					margin-bottom: .3em;
					min-height: 0!important;
				}
			}

			p{

				&:last-of-type{
					margin-bottom: 1.3em;

					@media (max-width: $md){
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.all-partners-section,
.image-lightbox-carousel-wrap{
	padding-top: 3.5rem;
	padding-bottom: 5.5rem;

	.container.text-con.text-con{
		max-width: 127.9rem;

		h1{
			text-transform: capitalize;
		}

		~ .container.text-con.text-con{
			margin-top: 10.7rem;
		}
	}

	.container.slide-con.slide-con,
	.container.slide-con {
		max-width: 153.6rem;
		max-width: calc(100% - 1.75rem - 1.75rem);
	}

	.swiper-wrapper-con{
		overflow: visible!important;
	}

	.text-top{

		@media (max-width: $md){
			margin-bottom: .1rem;
		}
	}

	.partner-list-slide{

		@media (max-width: 1279px){
			width: calc(100% + 1.75rem + 1.75rem + 1.75rem + 1.75rem);
			max-width: calc(100% + 1.75rem + 1.75rem + 1.75rem + 1.75rem);
			margin-left: -3.5rem;
		}
		
	}
	
	.swiper-slide{

		.img-con{

			@media (max-width: $md){
				margin-bottom: 2.2rem;
			}
		}

		h4{
			margin-bottom: .2em;
		}

		.under{

			@media (max-width: $md){
				margin-bottom: .8em;
			}
		}

		.tel-con,
		.email-con{
			font-weight: 500;

			a{
				font-weight: 400;
				text-decoration: none;
			}
		}
	}
	
}

.text-and-image-over-back-colour-section + .quote-slide-section{
	padding-top: 18.2rem;
	padding-bottom: 15.6rem;

	@media (max-width: $md){
		padding-top: 5.5rem;
		padding-bottom: 12.6rem;
	}
}

.quote-slide-section{
	position: relative;
	padding-top: 9.9rem;
	padding-bottom: 10.2rem;

	@media (max-width: $md){
		padding-top: 5.8rem;
		padding-bottom: 5.2rem;
	}

	&:after{
		content: '';
		position: absolute;
		left: 4.9rem;
		right: 4.9rem;
		top: 0rem;
		bottom: 0rem;
		background-image: url(../images/quote-feather.svg);
		background-repeat: no-repeat;

		@media (max-width: 1600px){
			left: 50%;
			right: 0;
			transform: translateX(-50%);
			max-width: calc(100vw - 2rem);
			width: 100%;
		}

		@media (max-width: $md){
			left: 50%;
			right: 0;
			transform: translateX(-50%);
			max-width: 102.3rem;
			width: 100%;
			background-image: none;
		}
	}

	&.bg-blue{

		&:after{
			background-color: $blue;
		}
	}

	&.bg-cream{

		&:after{
			background-color: $cream;
		}

		em{
			color: $gold;
		}

		.swiper-pagination.swiper-pagination-fraction{
			color: $green;
		}

		.swiper-button-prev,
		.swiper-button-next{

			&.swiper-button-disabled{

				svg{
	
					*{
						fill: $gold;
					}
				}
			}
		}
	}

	&.bg-gold{

		&:after{
			background-color: $gold;
		}

		.swiper-button-prev,
		.swiper-button-next{

			svg{
	
				*{
					fill: $green;
				}
			}
		}
	}

	&.bg-white{

		em{
			color: $gold;
		}

		.swiper-pagination.swiper-pagination-fraction{
			color: $green;
		}

		.swiper-button-prev,
		.swiper-button-next{

			svg{
	
				*{
					fill: $gold;
				}
			}

			&.swiper-button-disabled{

				svg{
	
					*{
						fill: $gold;
					}
				}
			}
		}
	}

	.quote-slide-con{
		display: flex;
		flex-wrap: wrap;
	}

	.quote-image-slide{
		width: 38.3%;
		width: 32.7%;
		margin: 0;

		@media (max-width: 1500px){
			
			@media (min-width: 1080px + 1){
				padding-left: 6rem;
			}
		}

		@media (max-width: 1080px){
			width: 100%;
			padding-left: 20.6rem;
			padding-right: 20.6rem;
		}

		@media (max-width: $md){
			padding-left: 5.6rem;
			padding-right: 5.6rem;
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}
	}

	.quote-slide{
		width: 48.4%;
		margin: 0 0 0 18.9%;
		text-align: center;

		@media (max-width: 1500px){
			
			@media (min-width: 1080px + 1){
				padding-right: 6rem;
			}
		}



		@media (max-width: 1080px){
			width: 100%;
			margin: 0 auto;

			@media (min-width: $md + 1){
				padding-left: 5rem;
				padding-right: 5rem;
			}
		}

		.inner{
			padding-top: 5.2rem;
			padding-bottom: 5.2rem;
			min-height: 57.3rem;
		}
	}

	.swiper-pagination.swiper-pagination-fraction{
		bottom: .9rem;
		@include font-size(24);
		line-height: 1;
		font-family: $alt-font;
		color: $cream;

		@media (max-width: 1500px){
			
			@media (min-width: 1080px + 1){
				padding-right: 6rem;
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next{
		transform: none;
		top: auto;
		bottom: 1.3rem;

		&.swiper-button-disabled{
			opacity: .25;

			svg{
	
				*{
					fill: $cream;
				}
			}
		}
	}

	.swiper-button-prev{
		left: calc(50% - 17.2rem);
		right: auto;

		@media (max-width: 1080px){
			left: 2.1rem;
		}
	}

	.swiper-button-next{
		right: calc(50% - 17.2rem);

		@media (max-width: 1500px){
			
			@media (min-width: 1080px + 1){
				///padding-left: 6rem;
				right: calc(50% - 11.2rem);
			}
		}

		@media (max-width: 1080px){
			right: 2.1rem;
		}
	}
}

.from-the-blog-section{
	padding-top: 8.8rem;
	padding-bottom: 1.2rem;

	@media (max-width: $md){
		padding-top: 2.8rem;
	}

	.container{
		max-width: 102.3rem;
		position: relative;
		z-index: 1;
	}

	.text-top{
		text-align: center;
		margin-bottom: 13.9rem;

		@media (max-width: $md){
			margin-bottom: 1.6rem;
		}

		.text{
			margin: 0 auto;
			width: 47rem;

			@media (max-width: $md){
				width: 20rem;
			}
		}
	}

	.btn-con{
		margin-top: 4.2rem;

		@media (max-width: $md){
			display: none;
		}
	}

	.below{

		@media (min-width: $md + 1){
			display: none;
		}

		@media (max-width: $md){
			
			.btn-con{
				display: flex;
				margin-top: 0;
				margin-bottom: 4.2rem;
			}
		}
	}
}

.our-partners-section + .instagram-section{
	padding-top: 6.2rem;

	@media (max-width: $md){
		padding-top: 2.2rem;
	}
}

.instagram-section{
	padding-top: 2.6rem;
	padding-bottom: 6.4rem;

	@media (max-width: $md){
		padding-bottom: 2.6rem;
	}
	
	.container{
		max-width: 100%;
		position: relative;
		z-index: 1;
	}

	.text-bottom,
	.text-top{
		text-align: center;
		margin-bottom: 3.5rem;

		@media (max-width: $md){
			margin-bottom: .6rem;
		}

		.text{
			margin: 0 auto;
			width: 62rem;
		}
	}

	.text-bottom{
		margin-bottom: 0;

		h4{

			em{
				font-size: 137.4%;
				display: inline-block;
				padding-top: .35em;

				@media (max-width: $md){
					font-size: 120%;
				}
			}
		}
	}

	.instagram-con{
		margin-bottom: 7.8rem;
		flex: 1 auto;
		overflow: hidden;
		
		@media (max-width: $md){
			padding-left: 7rem;
			padding-right: 7rem;
		}
	}

	#sb_instagram {
		.sb_instagram_header {
			display: none;
		}

		#sbi_images {
			padding: 0px !important;
			float: none;
			display: grid;
			gap: 10px;
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: min-content;

			@media (min-width: $md + 1){
				gap: 25px;
				grid-template-columns: repeat(10, 1fr);
				grid-template-rows: repeat(3, 1fr);
				width: 160%;
				max-width: 156%;
				margin-left: -28%;
			}


			@media (min-width: $xl + 1){
				gap: 35px;
			}

			.sbi_item {
				float: none;
				width: 100% !important;
				transition: all 0.5s !important;

				&:nth-child(3n + 1) {
					@media (max-width: $md){
						grid-column: span 2;
					}
				}

				@media (min-width: $md + 1){
					&:nth-child(1) { grid-area: 1 / 3 / 2 / 4; }
					&:nth-child(2) { grid-area: 1 / 4 / 2 / 5; }
					&:nth-child(3) { grid-area: 2 / 3 / 4 / 5; }
					&:nth-child(4) { grid-area: 1 / 5 / 3 / 7; }
					&:nth-child(5) { grid-area: 3 / 5 / 4 / 6; }
					&:nth-child(6) { grid-area: 3 / 6 / 4 / 7; }
					&:nth-child(7) { grid-area: 1 / 7 / 2 / 8; }
					&:nth-child(8) { grid-area: 1 / 8 / 2 / 9; }
					&:nth-child(9) { grid-area: 2 / 7 / 4 / 9; }
					&:nth-child(10) { grid-area: 1 / 9 / 3 / 11; }
					&:nth-child(11) { grid-area: 3 / 9 / 4 / 10; }
					&:nth-child(12) { grid-area: 1 / 1 / 3 / 3; }
					&:nth-child(13) { grid-area: 3 / 2 / 4 / 3; }
				}

				.sbi_photo {
					height: auto !important;
					aspect-ratio: 1
				}
			}
		}
		
	}
}
.parent {
display: grid;
grid-template-columns: repeat(10, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
}


.accordion-section + .text-blocks-section{
	padding-top: 11.5rem;

	@media (max-width: $md){
		padding-top: 7.2rem;
	}
}

.all-partners-section + .text-blocks-section{

	@media (max-width: $md){
		padding-top: 10.1rem;
	}
}

.text-blocks-section{
	padding-top: 8.1rem;
	padding-bottom: 1.7rem;

	@media (max-width: $md){
		padding-top: 4rem;
		padding-bottom: .1rem;
	}

	.container{
		max-width: 143.3rem;

		@media (min-width: 1150px + 1){
			padding-right: 9.35rem;
			padding-left: 9.35rem;
			
			.col-12{
				padding-right: 9.35rem;
				padding-left: 9.35rem;
			}

			.row{
				margin-right: -9.35rem;
				margin-left: -9.35rem;
			}
		}
	}

	.row{
		justify-content: center;
		text-align: center;
	}

	.col-md-4{
		margin-bottom: 1.75rem;

		@media (min-width: $md + 1){
			margin-bottom: 9.35rem;
		}

		@media (max-width: $md){
			margin-bottom: 3.35rem;
			padding-right: 7rem;
			padding-left: 7rem;
		}

		.icon-con{
			margin-bottom: 2.4rem;
		}

		img{
			margin: 0 auto;
		}

		h4{
			margin-bottom: .36em;
		}
	}
}

.our-partners-section{
	padding-top: .4rem;
	padding-bottom: 11.6rem;

	@media (max-width: $md){
		padding-bottom: 10.6rem;
	}

	.container{
		max-width: 120.7rem;
	}

	.text-top{
		text-align: center;
		margin-bottom: 3.85rem;

		@media (max-width: $md){
			margin-bottom: 1.15rem;
		}

		.text{
			margin: 0 auto;
			width: 47rem;
		}
	}

	.partners-slide-con{

		@media (max-width: $md){
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	.swiper-pagination.swiper-pagination-fraction{
		bottom: .9rem;
		@include font-size(24);
		line-height: 1;
		font-family: $alt-font;
		color: $green;
	}

	.swiper-button-prev,
	.swiper-button-next{
		transform: none;
		top: auto;
		bottom: 1.3rem;

		&.swiper-button-disabled{
			opacity: .25;

			svg{
	
				*{
					fill: $gold;
				}
			}
		}
	}

	.swiper-button-prev{
		left: calc(50% - 17.2rem);
		right: auto;

		@media (max-width: $md){
			left: 2.1rem;
		}
	}

	.swiper-button-next{
		right: calc(50% - 17.2rem);

		@media (max-width: $md){
			right: 2.1rem;
		}
	}

	.count-slide{
		width: calc(100% + 5.65rem + 5.65rem);
		max-width: calc(100% + 5.65rem + 5.65rem);
		margin-left: -5.65rem;

		@media (max-width: $md){
			width: calc(100% + 1.475rem + 1.475rem);
			max-width: calc(100% + 1.475rem + 1.475rem);
			margin-left: -1.475rem;
		}
	}

	.swiper-slide{
		padding: 0 5.65rem 9.15rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;

		@media (max-width: $md){
			padding: 0 1.475rem 9.15rem;
		}

		a{
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img{
			
			mix-blend-mode: multiply;
		}
	}
}

.hero-section{

	@media (min-width: $md + 1){
		
		&:has(+ .form-section){
			
			.row{
				padding-top: 3.8rem;
				align-items: flex-start;

				*{
					align-items: flex-start;
				}
			}

			.logo-link{
				margin: 0 auto 10.8rem;
			}
		}
	}

	@media (max-width: $md){
		
		&:has(+ .form-section){
			
			.row{
				align-items: flex-start;

				*{
					align-items: flex-start;
				}
			}

			.logo-link{
				margin: 0 auto 5rem;
			}
		}
	}
}

.hero-section + .form-section{
	margin-top: -23.1rem;
	position: relative;
	z-index: 4;
	
	@media (max-width: $md){
		margin-top: -27.8rem;
	}
}

.form-section{
	padding-bottom: 2rem;

	.container{
		max-width: 98.9rem;

		@media (max-width: $md){
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}

	.form-con{
		background-color: $cream;
		padding: 5rem 9.3rem 5.3rem;

		@media (max-width: $md){
			padding: 4.4rem 4.1rem 5.3rem;
		}
	}
}

.contact-section{
	padding-top: 8.2rem;
	padding-bottom: 8.2rem;

	@media (max-width: $md){
		padding-top: 6.9rem;
		padding-bottom: 3.5rem;
	}

	.row{
		align-items: center;
	}

	.text-top{
		text-align: center;
		margin-bottom: 6.25rem;

		@media (max-width: $md){
			margin-bottom: 2.45rem;
		}

		.text{
			margin: 0 auto;
			width: 48rem;
		}
	}

	.image-side{

		@media (max-width: $md){
			margin-bottom: 4.15rem;
		}

		.img-con{
			aspect-ratio: 1.4268/1;
		}
	}

	.contact-side{
		text-align: center;

		@media (min-width: $md + 1){
			padding-left: 14.6rem;
		}

		.text{
			width: 30rem;
			margin: 0 auto;
		}

		h5{
			margin-bottom: 1em;
		}

		address:has(+ h5){
			margin-bottom: 2.6em;

			@media (max-width: $md){
				margin-bottom: 1.5em;
			}
		}

		.tel-con,
		.email-con{
			color: $gold;

			+ .tel-con,
			+ .email-con{
				margin-top: .4em;
			}

			a{
				color: $colour;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					color: $gold;
				}
			}
		}
	}

	.text-side{

		h3{
			margin-bottom: .45em;
		}

		.btn-con{
			margin-top: 3.6rem;
		}
	}
}

.accordion-section{
	padding-top: 11.2rem;
	padding-bottom: 8.8rem;
	background-color: $cream;

	@media (max-width: $md){
		padding-top: 7.1rem;
		padding-bottom: 4.8rem;
	}

	.container{
		max-width: 102.6rem;
	}
}

dl.accordion{

	dt{
		border: .1817rem solid $gold;
		border-bottom: none;
		@include font-size(28);
		@include line-height(28,28);
		font-family: $alt-font;
		color: $green;
		padding: 3.4rem 6rem 3.2rem 3.9rem;
		position: relative;
		transition: $time;
		cursor: pointer;

		@media (max-width: $md){
			padding: 3.4rem 5rem 3.2rem 2.5rem;
		}

		&.open{
			color: $gold;
			padding: 3.3rem 6rem 2rem 3.9rem;

			@media (max-width: $md){
				padding: 3.3rem 5rem 1.1rem 2.5rem;
			}

			i{

				svg{

					path{
						fill: $gold;
					}

					&:nth-of-type(2){
						transform: translate(-50%, -50%) rotate(0deg);
					}
				}
			}
		}

		i{
			position: absolute;
			top: 3.9rem;
			right: 3rem;
			width: 1.54416rem;
			height: 1.54416rem;

			@media (max-width: $md){
				right: 2rem;
			}


			svg{
				width: 1.54416rem;
				height: 2.452rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transition: $time;

				path{
					fill: $green;
					transition: $time;
				}

				&:nth-of-type(1){
					transform: translate(-50%, -50%) rotate(0deg);
				}

				&:nth-of-type(2){
					transform: translate(-50%, -50%) rotate(-90deg);
				}

			}
		}
	}

	dd{
		border: .1817rem solid $gold;
		border-top: none;
		max-height: 0;
		overflow: hidden;
		margin: 0 0 2.3rem;
		transition: $time;

		@media (max-width: $md){
			margin: 0 0 2.0rem;
		}
		
		.inner{
			padding: 0rem 6rem 1.7rem 3.9rem;

			@media (max-width: $md){
				padding: 0rem 5rem 2rem 2.5rem;
			}
		}
	}
}

.blog-top-section{
	background-color: $cream;
	text-align: center;
	padding-top: 10.2rem;
	padding-bottom: 6.7rem;

	@media (max-width: $md){
		padding-top: 11.4rem;
		padding-bottom: 3.7rem;
	}

	.container {
		max-width: 107.9rem;
	}

	.date{
		margin-bottom: -2.6rem;

		h3{

			em{
				@include font-size(44);
				@include line-height(44,60);
			}
		}
	}

	.top,
	.bottom{
		@include line-height(44,54);
		margin-bottom: .6em;

		@media (max-width: $md){
			@include font-size(32);
			@include line-height(32,36);
			margin-bottom: .8em;
		}
	}

	.bottom{
		color: $gold;
	}

	.cats{
		margin-bottom: 3.9rem;

		@media (max-width: $md){
			margin-bottom: 2.9rem;
		}

		a{
			text-decoration: none;
			text-transform: uppercase;
			opacity: .25;
			font-weight: 500;
			letter-spacing: .01em;
			@include font-size(18);

			&:hover,
			&:focus-visible{
				opacity: .75;
			}

			&:has(+ a){

				&:after{
					content: ', ';
				}
			}
		}
	}
}

.cms-h4 {    
	font-size: 3.2rem;
    line-height: 1.125;
	margin-bottom: 1em;

	@media (max-width: $md){
		margin-bottom: .5em;
	}

	em{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}

.simple-text-section{
	padding-top: 14.8rem;
	padding-bottom: 16.9rem;

	@media (max-width: $md){
		padding-top: 7.5rem;
		padding-bottom: 6.9rem;

		p{
			padding-left: 2.6rem;
			padding-right: .1rem;
		}
	}

	&:has(+ .image-section),
	&:has(+ .images-section),
	&:has(+ .simple-text-section){
		padding-bottom: 3.3rem;

		@media (max-width: $md){
			padding-bottom: .5rem;
		}
	}

	+ .simple-text-section{
		padding-top: 0;
	}


	.container {
		max-width: 102.1rem;
	}

	h4{
		margin-bottom: 1em;

		@media (max-width: $md){
			margin-bottom: .5em;

			&:has(em:first-of-type){
				position: relative;
				padding-left: 2.6rem;
				padding-right: .1rem;

				em{
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		em{
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;

		}
	}
}

.image-section,
.images-section{
	padding-bottom: 16.9rem;

	@media (max-width: $md){
		padding-bottom: 6.9rem;
	}

	&:has(+ .image-section),
	&:has(+ .images-section),
	&:has(+ .simple-text-section){
		padding-bottom: 3.3rem;

		@media (max-width: $md){
			padding-bottom: 2rem;
		}
	}

	&:has(+ .image-section),
	&:has(+ .images-section){

		@media (max-width: $md){
			padding-bottom: 3.3rem;
		}
	}

	.container {
		max-width: 102.1rem;
	}

	+ .simple-text-section{
		padding-top: 4.9rem;
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 37.3%;
			max-width: 37.3%;
		}

		@media (max-width: $md){
			padding-bottom: 3.3rem;
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 62.7%;
			max-width: 62.7%;
		}
	}

	img{
		width: 100%;
	}
}

.share-section{
	background-color: $cream;
	text-align: center;
	padding-top: 9rem;
	padding-bottom: 8.6rem;

	@media (max-width: $md){
		padding-top: 7.7rem;
	}

	.container {
		max-width: 104.9rem;
	}

	.share{
		margin-bottom: -2.6rem;

		h3{

			em{
				@include font-size(44);
				@include line-height(44,60);
				color: $green;
			}
		}
	}

	.top{
		color: $gold;
		@include line-height(44,54);
		margin-bottom: 1.35em;

		@media (max-width: $md){
			@include font-size(32);
			@include line-height(32,36);
			margin-bottom: .8em;
		}
	}

	.social-menu{
		justify-content: center;
		margin-top: 5rem;

		a{
			width: 6.6rem;
			height: 6.6rem;
			line-height: 6.6rem;
			border-radius: 6.6rem;
			background-color: rgba($green, .05);
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 1.3rem;
		}
	}
}

.four-zero-four-section{
	padding-top: 14rem;
	padding-bottom: 14rem;
	text-align: center;
	background-color: $blue;
}

.embed-code-section.view-360 {
	p {
		line-height: 1;
		margin: 0;
	}

	iframe {
		height: calc(100vh - 40px);

		@media (min-width: $lg + 1) { 
			height: calc(100vh - 140px);
		}
	}
}

.wp-video,
.wp-video-shortcode {
	width: 100% !important;
}

.image-credit {
	text-align: center;
	margin-top: 20px;

	p {
		font-size: 1.5rem;
		letter-spacing: 1px;
		margin: 0;
	}

	a {
		text-decoration: underline;
		text-underline-position: under;

		&:hover {
			text-decoration: underline;
		}
	}
}


.image-lightbox-carousel-wrap {
	padding-top: 10rem;
	padding-bottom: 7rem;
	background-color: #F6F0E9;
	
	.container {
		max-width: 153.6rem;
		max-width: calc(100% - 1.75rem - 1.75rem);

		&.thin {
			max-width: 127.9rem;	
		}

		h2 {
			
		}
	}

	.partner-list-slide {
		width: 100dvw;
	}

	.swiper-wrapper {
		

		.swiper-slide {
			width: clamp(150px, 25vw, 400px);
			padding: 0 10px;

			a {
				display: block;
			}
		}
	}

	img {
		width: 100%;
	}

	@media (max-width: $md){
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}
 








































@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.row{
		margin-right: -2rem;
		margin-left: -2rem;
	}
}