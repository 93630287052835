
//!!FORM
::-webkit-input-placeholder {
	@include font-size(24);
	color: $blue;
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(24);
	color: $blue;
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(24);
	color: $blue;
}

:-ms-input-placeholder {
	@include font-size(24);
	color: $blue;
}

.website-loader {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(0deg);
	width: 20px;
	height: 20px;
	animation-name: loading-rotate;
	animation-iteration-count: infinite;
	animation-duration: 3.6s;
	transform-origin: 50% 50%;
	animation-timing-function: linear;
	border: 2px solid $gold;
	border-radius: 0 10px 10px 10px;
	border-width: 1px;
	z-index: 1;

	@keyframes loading-rotate{
		0%,
		100%{
			transform: translate(-50%, -50%) rotate(0deg);
			border-width: 1px;
		}

		99.999%{
			transform: translate(-50%, -50%) rotate(359.999deg);
			border-width: 10px;
		}
	}
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 3rem;
$label-bottom-margin: -.4rem;

input,
input.input-text,
textarea,
.fake-select,
select{
	display: block;
	padding: .8rem 0;
	@include font-size(24);
	@include line-height(24,24);
	font-weight: 400;
	color: $blue;
	font-family: $alt-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	border: 1px solid $blue;
	border-top: none;
	border-right: none;
	border-left: none;
	background-color: transparent;
	border-radius: 0;
	-webkit-appearance: none;
	box-shadow: none;
}

input,
input.input-text,
.fake-select,
select{
	border: none;
	background-image: url(../images/form-line.svg);
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: 100% .175rem;

	&:focus-visible{
		background-image: url(../images/form-line-gold.svg);
	}
}

label + .wpcf7-form-control-wrap:has(textarea){
	margin-top: 1.6rem;
}

textarea{
	border: .175rem solid $blue;
	height: 14.4rem;
	min-height: 14.4rem;
	min-width: 100%;
	@include font-size(20);
	font-family: $main-font;
	color: rgba($blue, .5);
	padding: 2.2rem 2rem;

	@media (max-width: $md){
		padding: 2rem 1.6rem;
		height: 17.7rem;
		min-height: 17.7rem;
	}

	&::-webkit-input-placeholder {
		@include font-size(20);
		color: rgba($blue, .5);
	}
	 
	&:-moz-placeholder { /* Firefox 18- */
		@include font-size(20);
		color: rgba($blue, .5);
	}
	
	&::-moz-placeholder {  /* Firefox 19+ */
		@include font-size(20);
		color: rgba($blue, .5);
	}
	
	&:-ms-input-placeholder {
		@include font-size(20);
		color: rgba($blue, .5);
	}
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

input::-webkit-date-and-time-value {
	text-align: left;
}

//
//!!SELECT START
//
.fake-select,
select{
	padding-right: 2.4rem;
	@include font-size(20);
	appearance: none;
	background-image: url(../images/select-icon.svg), url(../images/form-line.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%, 50% 100%;
	background-size: 1.5162rem auto, 100% .175rem;
	line-height: 4.6rem - .175rem;
	height: 4.6rem;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	color: rgba($blue, .5);

	&:focus-visible{
		background-image: url(../images/select-icon.svg), url(../images/form-line-gold.svg);
	}

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: .05rem;
		padding-bottom: .05rem;
		color: $black;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(24);
	@include line-height(24,30);
	font-family: $alt-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 400;
	color: $blue;
	margin-top: .4rem;

	span{
		color: rgba($white, .5);
	}

	.gfield_required{
		
		&:after{
			content: '*';
			color: rgba($white, .5);
		}
		
		span{
			display: none;
		}
	}
}

// *[type="submit"],
// .submit-con [type="submit"]{
// 	@extend %btn;
// 	width: auto;
// 	margin-top: 5px;
// 	min-width: 0;

// 	@media (max-width: $md){
// 		margin-top: -30px;
// 	}
// }

*[type="submit"]{
	margin-bottom: .9rem;
	width: 100%;
}

.submit-con{
	display: flex;
	justify-content: flex-end;
	margin-top: 3.2rem;

	*[type="submit"]{
		justify-content: center;
	}
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 3.4rem!important;
		position: relative;
		cursor: pointer;
		@include font-size(18);
		line-height: 1.732rem;
		@include inline;
		margin-top: 0;
		margin-bottom: .825rem;
		font-style: normal;
		color: $blue;
		font-weight: 400;
		text-transform: none;
		text-align: left!important;
		font-family: $main-font;
		width: 100%;

		@media (max-width: $md){
			line-height: 2.532rem;
		}

		a{
			color: $white;

			&:hover,
			&:focus-visible{
				color: $black;
			}
		}

		&:before{
			content: "";
			position: absolute;
			width: 2.4139rem;
			height: 2.6466rem;
			left: 0;
			top: -.6rem;
			border-radius: 0;
			background-image: url(../images/check.svg);
			background-repeat: no-repeat;
			background-size: 2.4139rem 2.6466rem;
		}

		&:after{
			content: "";
			position: absolute;
			width: 2.4139rem;
			height: 2.6466rem;
			left: 0;
			top: -.6rem;
			border-radius: 0;
			background-image: url(../images/check-tick.svg);
			background-repeat: no-repeat;
			opacity: 0;
			transition: $time;
			background-size: 2.4139rem 2.6466rem;
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

// input[type="radio"]{

// 	& + span,
// 	& + .wpcf7-list-item-label,
// 	& + label{

// 		&:before,
// 		&:after{
// 			border-radius: 50%;
// 		}
// 	}
// }

//
//!!RADIO & CHECKBOX END
//


//
//!!CONTACT FORM 7 START
//
.send-con{
	display: flex;
	justify-content: flex-end;
	padding: .3rem 0 0;
}

.wpcf7-form.invalid{

	*[type="submit"], 
	.submit-con [type="submit"]{
		order: 9999;
	}
}

.wpcf7-form-control-wrap:has(.wpcf7-acceptance){
	width: 100%!important;
	max-width: 100%!important;
}

.wpcf7-acceptance{
	
	label{
		width: 100%!important;
		margin-right: 0!important;
	}
}

.wpcf7{
	position: relative;

	.col{
		position: static;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		.field-p{
			margin-bottom: 0;
		}

		&.sent{

			.wpcf7-list-item{

				label{
					opacity: 1;
				}
			}

			label,
			.wpcf7-form-control-wrap,
			input[type="submit"],
			button,
			.num-button{
				pointer-events: none;
				opacity: .2;
				transition: .4s;
			}
		}

		label{

			span{
				color: $red;
			}
		}

		.wpcf7-form-control-wrap{
			display: block;
			width: 100%;
		}

		> div{
			width: 100%;
		}

		.form-text{
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.small,
		.medium,
		.large{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			margin-bottom: $input-bottom-margin;

			@media (max-width: $md){
				margin-bottom: 2.7rem;
			}
		}

		.small{
			width: 32%;
			width: calc(33.333% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (max-width: $md){
				width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $sm){
				width: 100%;
			}
		}

		.medium{
			width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));

			@media (max-width: $md){
				width: 100%;
			}
		}

		.large{
			width: 100%;
		}

		.has-label{
			display: flex;
			align-items: baseline;
		}

		.btn-con{
			position: static;
			justify-content: center;
		}

		span.wpcf7-not-valid-tip{
			position: absolute;
			background-color: transparent;
			color: $red!important;
			@include font-size(13);
			font-weight: 400;
			line-height: 1.2;
			width: auto;
			top: calc(100% - -0.13rem);
			transition: $time;
			left: 0px;

			@media (max-width: $md){
				@include font-size(11);
				top: calc(100% - -0.03rem);
			}
		}

		.wpcf7-acceptance,
		.wpcf7-checkbox,
		.wpcf7-radio{
			display: block;

			label{
				margin-bottom: $label-bottom-margin;
			}

			.wpcf7-list-item-label{

			}
		}

		.wpcf7-list-item{
			display: block;
			margin: 0;

			.wpcf7-list-item-label{
				margin-bottom: 0;
			}

			&:last-of-type{

				label{
					margin-bottom: 0;
				}
			}

			label{
				margin-bottom: $input-bottom-margin/2;
				margin-bottom: 0.825rem;
				text-align: left;
				margin-top: 0;
			}
		}
	}

	.wpcf7-spinner{
		background-color: $red;
		display: none!important;
	}

	.wpcf7-form.submitting{
		position: relative;

		[type="submit"],
		.wpcf7-form-control-wrap{
			pointer-events: none;
			opacity: .2;
		}

		&:before{
			@extend .website-loader;
		}
	}

	div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
	div.wpcf7-mail-sent-ok,
	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing{
		padding: .3rem .5rem;
		@include font-size(18);
		@include line-height(18,24);
		font-weight: 400;
		border: .175rem solid $red;
		color: $red;
		text-align: center;
		margin: 1rem 0;
		width: 100%;
	}

	.sent{

		div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output{
			color: $green;
			border-color: $green;
		}
	}

	div.wpcf7-mail-sent-ok{
		background-color: $green;
		border-color: $green;
	}

	.LV_validation_message{
		display: none;
	}
}

//
//!!CONTACT FORM 7 END
//