//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;
	
	.scroll-down &,
	.scroll-up &{

		@media (min-width: $lg + 1){
			background-color: $green;
		}

		@media (max-width: $lg){
			background-color: transparent;
		}
		
		.left .arrow-link,
		.right .btn,
		#site-logo{
			opacity: 1;
			visibility: visible;
		}

		.burger:not(.open){

			.line1:after,
			.line1{
				background-image: url(../images/burger-line2-gold.png);
			}

			.line1:before{
				background-image: url(../images/burger-line2-gold.png);
			}
		}

		// #site-logo,
		// #mainMenu{

		// 	svg{
		// 		filter: drop-shadow(0 .3rem .6rem rgba($black, .36));
		// 	}

		// 	a{
		// 		text-shadow: 0 .3rem .6rem rgba($black, .36);
		// 	}
		// }

		// #site-logo,
		// #mainMenu{
		// 	opacity: 0;
		// 	visibility: hidden;
		// }
	}

	@media (max-width: $lg){
		z-index: 111;
		margin-bottom: -1px;
	}


	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $lg){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $lg + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }

}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 5.94rem;
	transition: 0s;
	transition: $time;
	opacity: 0;
	visibility: hidden;

	@media (max-width: $lg){
		width: 7.404rem;
		filter: drop-shadow(0 .33rem .6rem rgba($black, .1));
	}

	svg,
	img{
		display: block;
		width: 100%;
	}
}

.menu-top-outer{
	transition: $time;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;

	@media (min-width: $lg + 1){
		
		.container{
			max-width: calc(100% - 6.5rem);
		}
	}

	@media (max-width: $lg){
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}
 	
	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		@media (max-width: $lg){
			position: static;
		}
	}

	.right,
	.left{
		display: flex;
		align-items: center;
		min-width: 38rem;

		@media (max-width: $lg){
			min-width: 0;
		}

		.arrow-link,
		.btn{
			opacity: 0;
			visibility: hidden;
		}

		.arrow-link{
			@include font-size(16);

			@media (max-width: $lg){
				display: none;
			}
		}
	}

	.left{
		justify-content: flex-start;
	}

	.right{
		justify-content: flex-end;

		@media (max-width: $lg){
			display: none;
		}

		.btn{

			&:hover,
			&:focus-visible{
				outline: none;
				background-color: $gold;
				border-color: $gold;
				color: $white!important;

				*{
					color: $white!important;
				}
			}
		}
	}
}

html.menu-open{


	:has(.main-menu li.open){
		
		.burger{
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}
}

.mob-lower-header{
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 110;
	background-color: $green;
	padding: 1.54rem 0;
	width: 100%;
	transform: translateY(100%);
	transition: $time;

	.show-lower &{
		transform: translateY(0%);
	}

	@media (min-width: $lg + 1){
		display: none;
	}

	.btn.btn{
		font-size: 1.6rem!important;
		min-width: 0!important;
		margin-bottom: 0!important;
		width: calc(50% - 2.25rem);
		padding: 1.45rem 0.4rem;

		&:first-of-type:not(:last-of-type){
			margin-right: .5rem;
		}

		&:last-of-type:not(:first-of-type){
			margin-left: .5rem;
		}

		&.gold{

			&:hover,
			&:focus-visible{
				color: $green!important;
			}
		}
	}
}

.menu-bottom-outer{
	background-color: $green;
	width: 52.6rem;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	transition: $time;
	transform: translateX(-100%);

	@media (max-width: $lg){
		width: 36rem;
	}

	.menu-open &{
		transform: translateX(0%);
	}

	.col-12{
		height: 100vh;
		padding-top: 0rem;
		padding-bottom: 17.1rem;

		@media (max-width: $md){
			padding-bottom: 24rem;
		}

		#mainMenu{
			padding-top: 10.7rem;
			//height: calc(100vh - 10.7rem - 7.4rem);
			max-height: calc(100vh - 10.7rem - 7.4rem);
			overflow: auto;

			@media (max-width: $lg){
				//height: calc(100vh - 10.7rem - 3.4rem);
				max-height: calc(100vh - 10.7rem - 3.4rem);
			}
		}
	}

	@media (min-width: $lg + 1){
		
		.container{
			max-width: calc(100% - 6.5rem);
		}
	}
	
	img{
		width: 10.148rem;
		position: absolute;
		left: 1.7rem;
		bottom: 3.6rem;

		@media (max-width: $md){
			bottom: 13.6rem;
		}
	}

}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	margin: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	transition: $time;

	// @media (max-width: $lg){
	// 	margin: 0;
	// 	position: absolute;
	// 	top: 0;
	// 	background-color: $green;
	// 	left: 0;
	// 	padding-top: 15.3rem;
	// 	padding-bottom: 1rem;
	// 	height: 100vh;
	// 	width: 100%;
	// 	flex-direction: column;
	// 	justify-content: flex-start;
	// 	opacity: 0;
	// 	visibility: hidden;
	// 	transition: $time;
	// 	padding-right: 2.0rem;
	// 	padding-left: 2.0rem;

	// 	.menu-open &{
	// 		opacity: 1;
	// 		visibility: visible;
	// 	}

	// 	.main-menu{
	// 		max-height: calc(100vh - 15.3rem - 1rem);
	// 		overflow: auto;
	// 	}
	// }
}

@media (min-width: $lg + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $gold;
			text-decoration: none;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-end;
	justify-content: center;
	position: relative;
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		width: 100%;
		
		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;
			cursor: pointer;

			&:hover,
			&:focus-visible{

				> a{
					background-image: url(../images/arrow-right-gold.svg);
				}
			}

			> a{
				position: relative;
				pointer-events: none;
				background-image: url(../images/arrow-right-white.svg);
				background-repeat: no-repeat;
				background-position: 100% 50%;
				padding-right: 4.5rem;
				background-size: 2.5rem 1.1rem;

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-right-gold.svg);
				}
			}

			&:hover,
			&:focus-visible{
				
				> a{
					color: $gold;
				}
			}

			&.open{

				// &:before{
				// 	transform: translateY(-50%) rotate(0deg);
				// }

				// .sub-menu{
				// 	pointer-events: auto!important;
				// }

				

				> .sub-menu{
					pointer-events: auto;
					transform: translateX(0);
				}

				> a{
					pointer-events: auto;
				}
			}

			.sub-menu{
				position: absolute;
				opacity: 1;
				visibility: visible;
				background-color: transparent;
				transform: none;
				width: 100%;
				left: 0rem;
				top: -10.7rem;
				pointer-events: none;
				padding: 10.7rem 0 0;
				margin: 0;
				justify-content: flex-start;
				transition: $time;
				text-align: left;
				background-color: transparent;
				background-color: $green;
				z-index: 20;
				height: calc(100vh - 16rem);
				transform: translateX(calc(100% + 3.25rem + 1.75rem));

				@media (max-width: $lg){
					padding: 12.4rem 0 0;
				}

				&:before{
					content: 'Back';
					position: absolute;
					top: 3.5rem;
					left: 0;
					background-image: url(../images/arrow-left-gold.svg);
					background-repeat: no-repeat;
					padding-left: 4rem;
					background-position: 0 50%;
					color: $gold;
					@include font-size(18);
					font-weight: 500;
					letter-spacing: .1em;
					text-transform: uppercase;
					background-size: 2.5rem 1.1rem;

					@media (max-width: $lg){
						top: 5.2rem;
					}
				}
				
				li{
					margin: 0 0;
					display: block;
					text-align: left;

					+ li{
						margin: 1.48rem 0 0;
					}
				}

				a{
					@include font-size(24);
					@include line-height(24,28);
					font-family: $main-font;
					padding: 0 0;
				}
			}
		}

		> a{
			@include font-size(32);
			@include line-height(32,40);
			color: $white;
			text-decoration: none;
			font-weight: 400;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: .85rem 0;
			z-index: 2;
			display: inline-block;
			letter-spacing: 0em;

			&:hover,
			&:focus-visible{
				color: $gold;
				text-decoration: none;
			}
		}
	}
}
